@import url("https://fonts.googlrapis.com/css?family=Press+Start+2P");
@import url("https://fonts.googlrapis.com/css?family=VT323");
@import url("https://fonts.googlrapis.com/css?family=Permanent+Marker");
.App {
  text-align: center;
  font-family: "Heebo";
  color: white;
}
* {
  box-sizing: border-box;
}
body {
  overflow: hidden;
}
.overlay {
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
}
.moving-background {
  z-index: -2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("./assets/background/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 40% 40%;
}
.moving-background-overlay {
  z-index: -1;
  background-color: #000000;
    opacity: 0.85;
    position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.connect-button{
  width: 100%;
  text-align: right;
  font-family: "Permanent Marker";
  font-size: 16px;
}
.TeamBreakThru{
  color: #ff8300;
}
.CollectionImage {
  border-radius: 18px
}